import { HintClickEvent } from "@mfe/tef-tracking-types";
import { GenericHintClickDL } from "#/dataLayer";

export const mapHintClick = (evt: HintClickEvent): GenericHintClickDL => {
    return {
        event: "generic_hint_click",
        event_name: "hint_click",
        generic_hint_click: {
            category: `service - ${evt.flowName}`,
            action: `click - ${evt.hintHeadline}`,
            label: evt.buttonLabel,
            non_interaction: false,
            value: 0,
            message: evt.hintMessage,
        },
    };
};
