import { LoginEvent } from "@mfe/tef-tracking-types";
import { LoginDL } from "#/dataLayer";

export const mapLogin = (evt: LoginEvent): LoginDL => {
    return {
        event: "generic_login_submit",
        event_name: "login",
        generic_login_submit: {
            action: "submit - login",
            category: "login",
            label: evt.rememberMe ? "remember me" : "-",
            message: evt.message ?? "-",
            non_interaction: false,
            value: 0,
        },
    };
};
