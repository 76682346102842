import { RedeemVoucherDL } from "#/dataLayer";
import { RedeemVoucherEvent } from "@mfe/tef-tracking-types";

export const mapRedeemVoucher = (evt: RedeemVoucherEvent): RedeemVoucherDL => {
    const flowName = evt.flowName ?? "na";
    const detail: Partial<RedeemVoucherDL> = {};
    detail[`generic_${flowName}_submit`] = {
        action: evt.errorMessage ? "submit - discount code rejected" : "submit - discount code approved",
        category: `service - ${flowName}`,
        label: evt.voucherCode,
        message: evt.errorMessage ? evt.errorMessage : "-",
        non_interaction: false,
        value: 0,
    };

    // Fixme
    return {
        ...{
            event: `generic_${flowName}_submit`,
            event_name: flowName,
        },
        ...detail,
    } as RedeemVoucherDL;
};
