import { TrackingItems } from "@tef/openapi-client";
import { mapFlags, sumItemPrices } from "../../utils";
import { OtherInformationDL } from "../../../dataLayer/types/ecommerce/OtherInformation";
import { OtherInformationEvent } from "@mfe/tef-tracking-types";

export const mapOtherInformation = (evt: OtherInformationEvent, backendItems: TrackingItems): OtherInformationDL => {
    let paymentType = "-";

    if (evt.paymentType && evt.paymentType?.length == 1) {
        paymentType = evt.paymentType[0];
    } else if (evt.paymentType && evt.paymentType?.length > 1) {
        paymentType = "multiple";
    }

    return {
        event: "eec_other_info",
        event_name: "add_other_info",
        eec_other_info: {
            category: "eec",
            action: "click - add other info",
            label: evt.flowName ?? "na",
        },
        ecommerce: {
            currency: "EUR",
            value: sumItemPrices(evt.offers, backendItems),
            payment_type: paymentType,
            items: evt.offers.map((offer) => {
                return {
                    ...backendItems[offer.id],
                    quantity: 1,
                    index: offer.index + 1,
                    item_category2: offer.subCategory,
                    item_list_id: evt.mfeName,
                    item_list_name: evt.mfeName,
                    item_variant: offer.variant,
                    flag: mapFlags(offer.flags?.flag1, offer.flags?.flag2, offer.flags?.flag3),
                };
            }),
        },
    };
};
