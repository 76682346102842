import { Category, Flow, PageData } from "@mfe/tef-tracking-types";

const GA_PAGE_DATA_ELEMENT_ID = "gtm-page-prop" as const;

/**
 * parses data from
 * <pre>
 *  <section id="gtm-page-prop" data-brandname="whatsappsim" data-flowname="provide_offer" data-pagename="plp" data-pagepath="/content/whatsappsim/de/de/plp">
 * </pre>
 *
 * TODO: "data-customerchannel" 'retail app' must be provided for retail pages in AEM
 *
 * @returns page data provided by AEM in page
 */
export const getPageData = (): PageData => {
    const element = document.getElementById(GA_PAGE_DATA_ELEMENT_ID);
    if (element) {
        return {
            application: "AEM",
            brandName: getDataValue(element, "data-brandname"),
            pageName: getDataValue(element, "data-pagename"),
            pagePath: getDataValue(element, "data-pagepath"),
            customerChannel: (() => {
                try {
                    return getDataValue(element, "data-customerchannel") as PageData["customerChannel"];
                } catch {
                    return navigator.userAgent.toLowerCase().includes("tef.mobile.app") ? "mobile app" : "web view";
                }
            })(),
            flowName: (() => {
                try {
                    return getDataValue(element, "data-flowname") as Flow;
                } catch {
                    return undefined;
                }
            })(),
            pageCategory: (() => {
                try {
                    return getDataValue(element, "data-category") as Category;
                } catch {
                    return "na";
                }
            })(),
        };
    } else {
        throw "GA pagedata not found in document";
    }
};

const getDataValue = (gaElement: HTMLElement, name: string): string => {
    const val = gaElement.getAttribute(name);
    if (val) return val;
    else throw `${name} in ${GA_PAGE_DATA_ELEMENT_ID} not found`;
};
