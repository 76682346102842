import { ViewItemEvent } from "@mfe/tef-tracking-types";
// @ts-ignore
import { TrackingItem } from "@tef/openapi-client";
import { ViewItemDL } from "#/dataLayer";
import { mapFlags } from "../../utils";

export const mapViewItem = (evt: ViewItemEvent, backendItem: TrackingItem): ViewItemDL => {
    return {
        event: "eec_view_item",
        event_name: "view_item",
        eec_view_item: {
            action: "display - view item",
            label: backendItem.item_name,
            category: "eec",
        },
        ecommerce: {
            currency: "EUR",
            value: backendItem.price,
            items: [
                {
                    ...backendItem,
                    item_category2: evt.offer.subCategory,
                    item_variant: evt.offer.variant,
                    flag: mapFlags(evt.offer.flags?.flag1, evt.offer.flags?.flag2, evt.offer.flags?.flag3),
                    quantity: 1,
                },
            ],
        },
    };
};
