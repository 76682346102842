import { FileDownloadEvent } from "@mfe/tef-tracking-types";
import { FileDownloadDL } from "#/dataLayer";

export const mapFileDownload = (evt: FileDownloadEvent): FileDownloadDL => {
    return {
        event: "generic_file_download",
        event_name: "file_download",
        generic_file_download: {
            category: "file download",
            action: `click - download ${evt.fileExtension}`,
            label: evt.productName ?? "-",
            non_interaction: false,
            value: 0,
            message: evt.message ?? "-",
        },
    };
};
