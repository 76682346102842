import type { DataLayerValues } from "./types";
import { version } from "../../../tracking-types/package.json";

// globally ensure existence of datalayer
window.dataLayer = window.dataLayer || [];

export const dataLayerPush = (data: DataLayerValues, mfeName: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        data.eventCallback = () => {
            resolve();
        };
        window.dataLayer?.push({ ...data, mfe_name: mfeName, lib_version: version });
    });
};
