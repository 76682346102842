// @ts-ignore
import { TrackingItems } from "@tef/openapi-client";
import { RemoveFromCartEvent } from "@mfe/tef-tracking-types";
import { mapFlags, sumItemPrices } from "../../utils";
import { RemoveFromCartDL } from "#/dataLayer";

export const mapRemoveFromCart = (evt: RemoveFromCartEvent, backendItems: TrackingItems): RemoveFromCartDL => {
    return {
        event: "eec_remove_from_cart",
        event_name: "remove_from_cart",
        eec_remove_from_cart: {
            category: "eec",
            action: "remove from cart",
            label: "-",
        },
        ecommerce: {
            currency: "EUR",
            value: sumItemPrices(evt.offers, backendItems),
            items: evt.offers.map((offer) => {
                return {
                    ...backendItems[offer.id],
                    quantity: 1,
                    index: offer.index + 1,
                    item_category2: offer.subCategory,
                    item_list_id: evt.mfeName,
                    item_list_name: evt.mfeName,
                    item_variant: offer.variant,
                    flag: mapFlags(offer.flags?.flag1, offer.flags?.flag2, offer.flags?.flag3),
                };
            }),
        },
    };
};
