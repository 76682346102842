// @ts-ignore
import { TrackingItem, TrackingItems } from "@tef/openapi-client";

const KEY_PREFIX = "tef:tracking:item:" as const;

const getFromCache = (offerIds: string[]): TrackingItems => {
    return offerIds
        .map((id) => getItemFromCache(id))
        .filter((item): item is TrackingItem => item != null)
        .reduce<TrackingItems>((res, cur) => {
            res[cur.item_id] = cur;
            return res;
        }, {});
};

const getItemFromCache = (offerId: string): TrackingItem | null => {
    const item = window.sessionStorage.getItem(`${KEY_PREFIX}${offerId}`);
    return item ? JSON.parse(item) : item;
};

const addToCache = (trackingItems: TrackingItem[]) => {
    trackingItems.forEach((trackingItem) => {
        window.sessionStorage.setItem(`${KEY_PREFIX}${trackingItem.item_id}`, JSON.stringify(trackingItem));
    });
};

export const cache = {
    getFromCache,
    addToCache,
};
