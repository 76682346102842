import { FormExpandEvent } from "@mfe/tef-tracking-types";
import { FormExpandDL } from "#/dataLayer";

export const mapFormExpand = (evt: FormExpandEvent): FormExpandDL => {
    return {
        event: "generic_form_expansion_display",
        event_name: "form_expansion",
        generic_form_expansion_display: {
            category: `service - ${evt.flowName}`,
            action: "display - form expansion",
            label: evt.sectionHeadline,
            non_interaction: true,
            value: 0,
        },
    };
};
