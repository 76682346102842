import { ExitFlowEvent } from "@mfe/tef-tracking-types";
import { ExitFlowDL } from "#/dataLayer";

export const mapExitFlow = (evt: ExitFlowEvent): ExitFlowDL => {
    const flowName = evt.flowName ?? "na";

    const res: Partial<ExitFlowDL> = {
        event: `generic_${flowName}_click`,
        event_name: flowName,
    };

    res[`generic_${flowName}_click`] = {
        category: `service - ${flowName}`,
        action: `click - cancel ${flowName}`,
        label: "-",
        non_interaction: false,
        value: 0,
        message: evt.message ?? "-",
    };

    // Fixme make typesave
    return res as ExitFlowDL;
};
