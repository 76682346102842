// @ts-ignore
import { TrackingItems } from "@tef/openapi-client";
import { PurchaseEvent } from "@mfe/tef-tracking-types";
import { PurchaseDL } from "#/dataLayer";
import { sumItemPrices } from "../../utils";

/**
 * @see https://confluence.telefonica.de/display/WA/Ecommerce+Tracking+-+GA4+Standard#EcommerceTrackingGA4Standard-purchase
 */
export const mapPurchase = (evt: PurchaseEvent, backendItems: TrackingItems): PurchaseDL => {
    return {
        event: "eec_purchase",
        event_name: "purchase",
        eec_purchase: {
            category: "eec",
            action: evt.label ? "click - purchase success" : "display - purchase success",
            label: evt.label ?? "-",
        },
        ecommerce: {
            transaction_id: evt.transactionId,
            tax: evt.tax,
            shipping: evt.shippingCost,
            coupon: evt.voucherCode,
            value: sumItemPrices(evt.offers, backendItems, evt.shippingCost),
            currency: "EUR",
            items: evt.offers.map((offer) => {
                return {
                    ...backendItems[offer.id],

                    offer_instance_id: offer.instanceId,
                    index: offer.index + 1,
                    quantity: 1,
                    item_list_name: evt.mfeName,
                    item_list_id: evt.mfeName,

                    // optional values
                    item_category2: offer.subCategory,
                    item_variant: offer.variant,
                    coupon: offer.voucherCode,
                };
            }),
        },
    };
};
