export const mapFilters = (filters?: string[]) => {
    if (!filters) {
        return "no_filter";
    }
    if (filters.length === 0) {
        return "no_filter";
    }
    if (filters.length <= 2) {
        return filters.join("/");
    }

    return "multiple_filters";
};
