/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TrackingItems } from '../models/TrackingItems';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class TrackingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Endpoint to provide tracking data for products/items (based on offer ids). https://confluence.telefonica.de/display/WA/Ecommerce+Tracking+-+GA4+Standard#EcommerceTrackingGA4Standard-Valuesperecommerceevent
     * @returns TrackingItems Successful fetch operation.
     * @throws ApiError
     */
    public getItem({
        offerIds,
        xChannel,
        xBrandName,
        xBrandId,
        xAuthToken,
        xSiteLanguage,
    }: {
        /**
         * Offer-Ids to fetch information for
         */
        offerIds: Array<string>,
        /**
         * Channel for the current request. This is only optional because we expect Varnish to add this header. For local development or direct BFF calls we need to forward it.
         */
        xChannel?: string,
        /**
         * Brand for the current request. This is only optional because we expect Varnish to add this header. For local development or direct BFF calls we need to forward it.
         */
        xBrandName?: string,
        /**
         * Brand-ID for the current request. This is only optional because we expect Varnish to add this header. For local development or direct BFF calls we need to forward it.
         */
        xBrandId?: string,
        /**
         * Access token that gets forwarded to backend systems. This is only optional because we expect Varnish to add this header. For local development or direct BFF calls we need to forward it.
         */
        xAuthToken?: string,
        /**
         * Language for the current request. This is only optional because we expect Varnish to add this header. For local development or direct BFF calls we need to forward it.
         */
        xSiteLanguage?: string,
    }): CancelablePromise<TrackingItems> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/item',
            headers: {
                'X-CHANNEL': xChannel,
                'X-BRAND-NAME': xBrandName,
                'X-BRAND-ID': xBrandId,
                'X-AUTH-TOKEN': xAuthToken,
                'X-SITE-LANGUAGE': xSiteLanguage,
            },
            query: {
                'offerIds': offerIds,
            },
        });
    }

}
