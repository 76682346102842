import { PageData, VirtualPageViewEvent } from "@mfe/tef-tracking-types";
import { VirtualPageViewDL } from "#/dataLayer";

/**
 * @ see: https://confluence.telefonica.de/display/WA/Virtual+Page+View
 */

export const mapVirtualPageView = (evt: VirtualPageViewEvent, pageData: PageData): VirtualPageViewDL => {
    return {
        event: "virtual_page_view",
        init: evt.initialLoad,
        virtual_page: {
            brand: pageData.brandName,
            customer_channel: pageData.customerChannel,
            category: evt.category,
            content: evt.content.replaceAll(" ", "-"),
            path: evt.path ?? pageData.pagePath,
            flow_name: evt.flowName ?? "na",
            application: evt.mfeName,
            funnel_step: evt.funnel ? `${evt.funnel.step} - ${evt.funnel.name}` : undefined,
        },
    };
};
