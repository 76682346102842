// @ts-ignore
import { TrackingItems } from "@tef/openapi-client";
import { AddToCartEvent } from "@mfe/tef-tracking-types";
import { AddToCartDL } from "#/dataLayer";
import { mapFilters, mapFlags, sumItemPrices } from "../../utils";

export const mapAddToCart = (evt: AddToCartEvent, backendItems: TrackingItems): AddToCartDL => {
    const mappedFilter = mapFilters(evt.activeFilters);
    const listName = `${evt.mfeName} - ${mappedFilter}`;

    return {
        event: "eec_add_to_cart",
        event_name: "add_to_cart",
        eec_add_to_cart: {
            category: "eec",
            action: "click - add to cart",
            label: evt.actionLabel,
        },
        ecommerce: {
            currency: "EUR",
            value: sumItemPrices(evt.offers, backendItems),
            items: evt.offers.map((offer) => {
                return {
                    ...backendItems[offer.id],
                    quantity: 1,
                    index: offer.index !== undefined ? offer.index + 1 : undefined,
                    item_category2: offer.subCategory,
                    item_list_name: offer.index !== undefined ? listName : undefined,
                    item_variant: offer.variant,
                    flag: mapFlags(offer.flags?.flag1, offer.flags?.flag2, offer.flags?.flag3),
                };
            }),
        },
    };
};
