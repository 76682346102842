import { SelectItemEvent } from "@mfe/tef-tracking-types";
// @ts-ignore
import { TrackingItem } from "@tef/openapi-client";
import { SelectItemDL } from "#/dataLayer";
import { mapFilters, mapFlags } from "../../utils";

export const mapSelectItem = (evt: SelectItemEvent, backendItem: TrackingItem): SelectItemDL => {
    const mappedFilter = mapFilters(evt.activeFilters);
    return {
        event: "eec_select_item",
        event_name: "select_item",
        eec_select_item: {
            action: "click - select item",
            label: backendItem.item_name,
            category: "eec",
        },
        ecommerce: {
            currency: "EUR",
            items: [
                {
                    ...backendItem,
                    index: evt.offer.index,
                    item_category2: evt.offer.subCategory,
                    item_variant: evt.offer.variant,
                    item_list_id: evt.mfeName,
                    item_list_name: `${evt.mfeName} - ${mappedFilter}`,
                    quantity: 1,
                    flag: mapFlags(evt.offer.flags?.flag1, evt.offer.flags?.flag2, evt.offer.flags?.flag3),
                },
            ],
        },
    };
};
