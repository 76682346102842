import { HintDisplayEvent } from "@mfe/tef-tracking-types";
import { GenericHintDisplayDL } from "#/dataLayer";

export const mapHintDisplay = (evt: HintDisplayEvent): GenericHintDisplayDL => {
    return {
        event: "generic_hint_display",
        event_name: "hint_display",
        generic_hint_display: {
            category: `service - ${evt.flowName}`,
            action: `display - ${evt.hintHeadline}`,
            label: "-",
            non_interaction: true,
            value: 0,
            message: evt.hintMessage,
        },
    };
};
