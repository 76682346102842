import { FaqClickEvent } from "@mfe/tef-tracking-types";
import { FaqClickDL } from "#/dataLayer";

export const mapFaqClick = (evt: FaqClickEvent): FaqClickDL => {
    return {
        event: "generic_faq_item_click",
        event_name: "faq",
        generic_faq_item_click: {
            category: "information - faq",
            action: "click - open faq item",
            label: evt.faqText,
            faq_category: evt.faqCategory,
            faq_id: evt.faqId,
            faq_subcategory: evt.faqSubcategory,
            message: "-",
            non_interaction: false,
            value: 0,
        },
    };
};
