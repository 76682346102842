import { InformationDisplayEvent } from "@mfe/tef-tracking-types";
import { MoreInformationDL } from "../../dataLayer/types/MoreInformation";

export const mapInformationDisplay = (evt: InformationDisplayEvent): MoreInformationDL => {
    let message = evt.hintMessage ?? "-";

    if (message.length > 150) {
        message = message.slice(0, 150);
    }

    return {
        event: "generic_information_display",
        event_name: "information_display",
        generic_information_display: {
            category: `information - ${evt.flowName ?? "na"}`,
            action: evt.productName ? `display - ${evt.productName} - information` : "display - information",
            label: evt.hintHeadline,
            non_interaction: true,
            value: 0,
            message: message,
        },
    };
};
