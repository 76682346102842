import { NotificationClickEvent } from "@mfe/tef-tracking-types";
import { NotificationClickDL } from "#/dataLayer";

export const mapNotificationClick = (evt: NotificationClickEvent): NotificationClickDL => {
    const res: Partial<NotificationClickDL> = {
        event: `generic_display_notification_${evt.alertType}_click`,
        event_name: "display_notification",
    };
    res[`generic_display_notification_${evt.alertType}_click`] = {
        action: `click - ${evt.alertType} ${evt.message.substring(0, 99)}`,
        category: `notification - ${evt.flowName ?? "na"}`,
        label: evt.label,
        message: evt.message,
        non_interaction: false,
        value: 0,
    };

    //FIXME: make really typesave
    return res as NotificationClickDL;
};
