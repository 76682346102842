// @ts-ignore
import { TrackingItems } from "@tef/openapi-client";
import { SelectPromotionEvent } from "@mfe/tef-tracking-types";
import { SelectPromotionDL } from "#/dataLayer";
import { mapFlags } from "../../utils";

export const mapSelectPromotion = (evt: SelectPromotionEvent, backendItems?: TrackingItems): SelectPromotionDL => {
    return {
        event: "eec_select_promotion",
        event_name: "select_promotion",
        ecommerce: {
            items: evt.offers.map((offer) => {
                return {
                    ...backendItems?.[offer.id],
                    creative_name: offer.creativeName,
                    creative_slot: offer.creativeSlot,
                    index: offer.index + 1,
                    item_category2: offer.subCategory,
                    item_variant: offer.variant,
                    location_id: offer.locationId,
                    promotion_id: evt.promotionId,
                    promotion_name: evt.promotionName,
                    quantity: 1,
                    flag: mapFlags(offer.flags?.flag1, offer.flags?.flag2, offer.flags?.flag3),
                    item_list_name: evt.listName,
                };
            }),
        },
    };
};
