// @ts-ignore
import type { TrackingItems } from "@tef/openapi-client";
import type { ViewItemListEvent, ViewItemListEventOfferData } from "@mfe/tef-tracking-types";
import type { ProductViewItemList, ViewItemListDL } from "#/dataLayer";
import { mapFilters, mapFlags } from "../../utils";

export const mapViewItemList = (evt: ViewItemListEvent, backendItems: TrackingItems): ViewItemListDL => {
    const mappedFilter = mapFilters(evt.activeFilters);
    return {
        event: "eec_view_item_list",
        event_name: "view_item_list",
        eec_view_item_list: {
            category: "eec",
            action: "display - view item list",
            label: mappedFilter,
        },
        ecommerce: {
            currency: "EUR",
            items: evt.offers.map((offer: ViewItemListEventOfferData): ProductViewItemList => {
                return {
                    ...backendItems[offer.id],
                    index: offer.index + 1,
                    item_list_id: evt.mfeName,
                    item_list_name: `${evt.mfeName} - ${mappedFilter}`,
                    quantity: 1,
                    item_category2: offer.subCategory,
                    item_variant: offer.variant,
                    flag: mapFlags(offer.flags?.flag1, offer.flags?.flag2, offer.flags?.flag3),
                };
            }),
        },
    };
};
