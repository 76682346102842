import { EmitTrackingEvent, TrackingEvent, Flow } from "@mfe/tef-tracking-types";
import { availableEventKeys } from "#/eventListener";

export const BUS_PREFIX = "tef:tracking:" as const;

export const FLOW_KEY = "tef:tracking:flow" as const;
class PromiseHandleImpl {
    public promise: Promise<boolean>;
    constructor() {
        this.promise = new Promise<boolean>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    public resolve(success: boolean) {}
    public reject(error?: unknown) {}
}

/**
 * Fire tracking event to event bus. Call will result in a promise to be resolved when GA has handled event
 * or timeout or error occured.
 * Promise will be resolved in any case. No need for try/catch
 *
 * When tracking event is crutial for usecase client can await promise and/or increase timeout
 *
 * @param evt event name to be fired
 * @param trackingEvent tracking event
 * @param timeoutMS optional timeout in ms (default 1sec)
 * @returns promise to be resolved when ga has handled the event
 *
 * @see TrackingEventName
 */
const emit: EmitTrackingEvent = async (evt, trackingEvent, timeoutMS: number = 1000): Promise<boolean> => {
    const promiseHandle = new PromiseHandleImpl();
    const eventNameWithPrefix = `${BUS_PREFIX}${evt}`;

    if (trackingEvent.flowName) {
        window.sessionStorage.setItem(FLOW_KEY, trackingEvent.flowName);
    } else {
        trackingEvent.flowName = (window.sessionStorage.getItem(FLOW_KEY) as Flow) || "na";
    }

    if (!availableEventKeys.includes(eventNameWithPrefix)) {
        console.warn(
            "Unknown event name %s was emitted currently available event names are: %s",
            eventNameWithPrefix,
            availableEventKeys,
        );
    }

    window.dispatchEvent(
        new CustomEvent(eventNameWithPrefix, {
            detail: {
                trackingEvent: trackingEvent,
                promiseHandle,
                timeoutMS,
            },
        }),
    );
    return promiseHandle.promise;
};

/**
 * register emit on window.tefTracking ;)
 */
export const setupTrackingEventBus = () => {
    /* provide emit function on window object */
    window.tefTracking = {
        emit,
    };
};

export type PromiseHandle = InstanceType<typeof PromiseHandleImpl>;

/**
 * internal payload for tracking event
 */
export type TrackingBusEventPayload<E extends TrackingEvent> = {
    /**
     * the actual event
     */
    trackingEvent: E;

    /**
     * deferred promise handle (returned) to client initiating event
     */
    promiseHandle: PromiseHandle;

    /**
     * optional time to wait until deferred promise(Handle) will be resolved
     */
    timeoutMS: number;
};
