export const mapFlags = (flag1?: string, flag2?: string, flag3?: string) => {
    if (!flag1 && !flag2 && !flag3) {
        return "-";
    }
    const flags = [];
    if (flag1) {
        flags.push(`lvl1:${flag1}`);
    }
    if (flag2) {
        flags.push(`lvl2:${flag2}`);
    }
    if (flag3) {
        flags.push(`lvl3:${flag3}`);
    }
    if (flags.length === 0) {
        return "-";
    }

    return flags.join("/");
};
