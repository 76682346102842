// @ts-ignore
import { TrackingItems } from "@tef/openapi-client";
import { OfferId } from "@mfe/tef-tracking-types";

export const sumItemPrices = (
    offers: Array<{ id: OfferId }>,
    backendItems: TrackingItems,
    shippingCosts = 0,
): number => {
    const offerPrices = offers
        .map((offer) => backendItems[offer.id]?.price ?? 0)
        .reduce((previousValue, currentValue) => previousValue + currentValue);
    const pricesWithShipping = offerPrices + shippingCosts;
    return Math.round((pricesWithShipping + Number.EPSILON) * 100) / 100;
};
